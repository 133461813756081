<template>
  <div class="col main_topics_wrapper_container">
    <!-- Start Server Table Large Screen  -->
    <server-table
      class="just_for_large"
      :count="categoriesTasksCount"
      :DataItems="categoriesTasks"
      :loading="loadingValue"
      @Refresh="getUserCategoriesData"
      @reset="filter = {}"
      :filter="filter"
      :title="`${localization('Topics')}`"
      edit_permission="add_topics"
    >
      <template #columns>
        <!-- sortable -->
        <el-table-column
          sortable
          prop="name"
          :label="`${localization('Name')}`"
        >
        </el-table-column>
        <el-table-column
          v-if="!is_english"
          prop="parent_name"
          label="Parent Name"
        >
        </el-table-column>
        <el-table-column
          v-if="is_english"
          prop="description"
          label="description"
        >
        </el-table-column>

        <el-table-column prop="" label="">
          <template slot-scope="scope">
            <div class="td_actions actions_wrapper">
              <a
                @click="open_add_task_dialog(2, scope.row)"
                v-tooltip.top-center="`${localization('Edit task')}`"
                class="actions-icons edit"
              >
                <i class="fa fa-edit"></i
              ></a>
              <a
                @click="handlerDeleteTopic(scope.row.id)"
                v-tooltip.top-center="`${localization('Delete task')}`"
                class=" actions-icons text-danger"
              >
                <i class="fa fa-trash"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <div type="info" class="ml-2" @click="open_add_task_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Task") }}
        </div>
      </template>
    </server-table>
    <!-- End Server Table Large Screen -->

    <!-- Start Server Table Small Screen  -->
    <server-table
      class="just_for_small"
      :count="categoriesTasksCount"
      :DataItems="categoriesTasks"
      :loading="loadingValue"
      @Refresh="getUserCategoriesData"
      @reset="filter = {}"
      :filter="filter"
      :title="`${localization('Topics')}`"
      edit_permission="add_topics"
    >
      <template #columns>
        <!-- sortable -->
        <el-table-column
          sortable
          prop="name"
          :label="`${localization('Name')}`"
        >
        </el-table-column>

        <el-table-column prop="" label="">
          <template slot-scope="scope">
            <div class="td_actions actions_wrapper">
              <a
                @click="open_add_task_dialog(2, scope.row)"
                v-tooltip.top-center="`${localization('Edit task')}`"
                class="actions-icons edit"
              >
                <i class="fa fa-edit"></i
              ></a>
              <a
                @click="handlerDeleteTopic(scope.row.id)"
                v-tooltip.top-center="`${localization('Delete task')}`"
                class=" actions-icons text-danger"
              >
                <i class="fa fa-trash"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <div type="info" class="ml-2" @click="open_add_task_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Task") }}
        </div>
      </template>
    </server-table>
    <!-- End Server Table Small Screen -->

    <!-- Start Add Or Edit New Task -->
    <el-dialog
      :title="type == 1 ? 'Add Task' : 'Edit Task'"
      :visible.sync="add_category_dialog"
      width="50%"
      top="4vh"
    >
      <el-form :model="add_task_data" :rules="rules" ref="addTeam">
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            :placeholder="`${localization('Write Name')}`"
            v-model="add_task_data.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="!is_english"
          label="Parent Name"
          class="w-100 mr-lg-3"
        >
          <el-select
            v-model="add_task_data.parent_id"
            :placeholder="`${localization('Please select a topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="item in allVersions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            :placeholder="`${localization('Write description')}`"
            v-model="add_task_data.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button @click="close_add_task_dialog" class="text-center d-block">{{
          localization("Cancel")
        }}</el-button>
        <el-button
          type="submit"
          @click="addNewTask"
          class="text-center d-block text-capitalize"
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!--End Add Or Edit New Task -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // {"name" : "task default edit" , "description" : "task defaut testing with Nada" , "topic_id" : "2"}
      // categories/tasks/save/2 handlerDeleteTaskCategoryTopics
      task_id: null,
      type: null,
      add_category_dialog: false,
      filter: {},
      add_task_data: {
        // topic_id: this.$route.params.version_id,
        name: null,
        description: null,
        parent_id: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        parent_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      allVersions: [],
      languages: [],
      is_english: false
    };
  },

  components: { ServerTable },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("topics/GetTopics", { query: query }).then(_ => {
        this.languages = this.$store.getters["topics/languages"];
        // this.getUserCategoriesData();
        this.$store
          .dispatch(
            "topics/handlerCategoriesTasks",
            this.$route.params.version_id
          )
          .then(res => {
            this.allVersions = res.versions;

            const found = this.languages.find(
              element => element.id == res.topic_language
            );
            if (found.title.toLowerCase() == "english") {
              this.is_english = true;
            } else {
              this.is_english = false;
            }
          });
      });
    },
    getUserCategoriesData() {
      this.$store
        .dispatch(
          "topics/handlerCategoriesTasks",
          this.$route.params.version_id
        )
        .then(res => {
          this.allVersions = res.versions;
        });
    },

    addNewTask() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("topics/addTopicTask", {
                topic_id: this.$route.params.version_id,
                name: this.add_task_data.name,
                description: this.add_task_data.description,
                parent_id: this.add_task_data.parent_id
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.getUserCategoriesData();
                this.add_task_data = this.add_task_data;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          } else {
            this.$store
              .dispatch("topics/addTopicTask", {
                id: this.task_id,
                // topic_id: this.$route.params.id,
                name: this.add_task_data.name,
                description: this.add_task_data.description,
                parent_id: this.add_task_data.parent_id
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.getUserCategoriesData();
                this.add_task_data = this.add_task_data;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          }
        }
      });
    },
    // open add Task Category dialog
    open_add_task_dialog(type, item) {
      this.add_category_dialog = !this.add_category_dialog;
      this.add_task_data = item;
      this.type = type;
      this.task_id = item.id;
    },
    // close add Task Category dialog

    // close add Task Category dialog
    close_add_task_dialog() {
      this.getUserCategoriesData();
      this.add_category_dialog = false;
      this.add_task_data = {};
      this.type = null;
      this.task_id = null;
    },

    handlerDeleteTopic(id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("topics/handlerDeleteTaskCategoryTopics", id)
            .then(_ => {
              this.getUserCategoriesData();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`
              });
            });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      loadingValue: ["topics/usersLoad"],
      categoriesTasks: ["topics/getCategoriesTasks"],
      categoriesTasksCount: ["topics/getCategoriesTasksCount"]
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
.actions_wrapper {
  display: flex;
  gap: 10px;
}
.just_for_small {
  display: none;
  @media (min-width: 320px) and (max-width: 991px) {
    display: block;
  }
}
.just_for_large {
  display: block;
  @media (min-width: 320px) and (max-width: 991px) {
    display: none;
  }
}
</style>
